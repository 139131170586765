import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ReactLoading from "react-loading";
import Modal from "react-modal";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import dateFnsFormat from "date-fns/format";

import { useQuery, useMutation } from "@apollo/client";

import classNames from "classnames";
import moment from "moment";

import { CopyToClipboard } from "react-copy-to-clipboard";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import { shortCoverageNames } from "./helpers";

import { getStyles } from "./formValidation";
import { centsToDollars } from "../../utils/currency";
import { startAndEnd } from "../../utils/truncate";
import { formatDate, parseDate } from "../../utils/date";

import UpdateAssignmentModal from "./UpdateAssignmentModal";
import CancelAssignmentModal from "./CancelAssignmentModal";
import PageHeader from "../../components/PageHeader";
import AutoSizingInputAdvanced from "../../components/AutoSizingInputAdvanced";
import PopoverMenu from "../../components/PopoverMenu";
import CurrencyInput from "../../components/CurrencyInput";

import { states } from "../../constants/states";

import { ASSIGNMENT_INVOICE_HISTORY, JOB_CATEGORY_QUERY } from "./queries";

import {
  CREATE_QUOTE,
  CREATE_ASSSIGNMENT,
  UPDATE_ASSIGNMENT_WAGE,
} from "./mutations";

import { environments } from '../../constants/environments';

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few secs",
    ss: "%d secs",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const FORMAT = "MM/dd/yyyy";

const WORKERS_COMP = "workers-comp";
const GENERAL = "general";
const MEDIA = "media";
const today = new Date();
let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

let oneYear = new Date();
oneYear.setDate(oneYear.getDate() + 365);

let contentStyle = {
  width: "40%",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )",
};

let modalStyles = {
  content: contentStyle,
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const getValidationSchema = (isPurchaseOrderWorkflowEnabled) => {
  return Yup.object().shape({
    contractor: Yup.string().required("Required"),
    jobName: Yup.string().required("Required"),
    jobDescription: Yup.string().required("Required"),
    jobWage: Yup.number("Must be a number type")
      .positive("Must be a positive value")
      .required("Please enter a wage. The field cannot be blank.")
      .min(1, "The wage must be greater than or equal to 1"),
    effectiveDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
    entityName: Yup.string().required("Required"),
    purchaseOrderContactInfo: isPurchaseOrderWorkflowEnabled
      ? Yup.object().shape({
          name: Yup.string().required("PO Contact Name is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("PO Contact Email is required"),
        })
      : Yup.object().shape({
          name: Yup.string(),
          email: Yup.string().email("Invalid email"),
        }),
  });
};

const JobAssignmentForm = (props) => {
  const {
    errors,
    errorMessage,
    data,
    isSubmitting,
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    categoryCodeData,
    isPurchaseOrderWorkflowEnabled,
  } = props;

  const quotes = data?.quotes?.edges;
  const quotes_with_active_policies = quotes.filter(
    (obj) => obj?.node?.policy?.edges[0]?.node?.isActive
  );

  const [selectedValue, setSelectedValue] = useState(
    quotes_with_active_policies[0].node.publicId
  );
  const [clientEntityAddress, setClientEntityAddress] = useState(false);

  const preapprovals = getPreapprovals(data?.quotes?.edges);

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">
        There was an error creating the job assignment. Please try again.
      </span>
    </div>
  );

  function getPreapprovals(quotes) {
    if (!quotes || quotes.length == 0) {
      return [];
    }

    const preapprovals = quotes
      .map((obj) => {
        const policy = obj?.node?.policy?.edges[0]?.node;
        return {
          ["policyId"]: policy?.publicId,
          ["quoteId"]: obj?.node.publicId,
          ["jobCategoryCode"]: obj?.node.job?.jobCategory?.code,
          ["jobCategory"]: obj?.node.job?.jobCategory?.className,
          ["coverageTypes"]: shortCoverageNames(obj?.node?.coverageType?.edges),
          ["quoteJsonWorkState"]: obj?.node?.quoteJsonWorkState,
          ["quoteJsonResidenceState"]: obj?.node?.quoteJsonResidenceState,
          ["isActive"]: policy?.isActive,
        };
      })
      .filter(
        (value) => value.policyId !== null && value.policyId !== undefined
      )
      .filter((value) => value !== null && value !== undefined)
      .filter((value) => value.isActive === true);

    return preapprovals;
  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    // Update Formik's field value
    setFieldValue(event.target.name, event.target.value);
  };

  const renderNewApprovalFields = () => {
    const jobCategoryOptions = (dropdownOptions) => {
      const filteredOptions = dropdownOptions.filter(
        (option) => option.className !== null
      );
      return (
        <Field
          component="select"
          name="jobCategoryCode"
          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
        >
          {filteredOptions.map((option) => (
            <option key={option.code} value={option.code}>
              {startAndEnd(option.className)}
            </option>
          ))}
        </Field>
      );
    };

    const coverageTypeOptions = () => {
      const dropdownOptions = [
        { label: "WC", key: "workers-comp" },
        { label: "GL", key: "general" },
        { label: "WC, GL", key: "workers-comp,general" },
      ];

      return (
        <Field
          component="select"
          name="coverageTypes"
          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
        >
          {dropdownOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </Field>
      );
    };

    const stateOptions = (dropdownOptions, locationType) => {
      return (
        <Field
          component="select"
          name={locationType}
          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
        >
          {dropdownOptions.map((option) => (
            <option
              key={[option.abbreviation, locationType].join("-")}
              value={option.abbreviation}
            >
              {option.abbreviation}
            </option>
          ))}
        </Field>
      );
    };

    return (
      <tr className="bb" key="newFields">
        <td className="tl ph2 pv3 f7"></td>
        <td className="tl ph2 pv3 f7">
          {jobCategoryOptions(categoryCodeData?.jobCategory)}
        </td>
        <td className="tl ph2 pv3 f7">{coverageTypeOptions()}</td>
        <td className="tl ph2 pv3 f7">{stateOptions(states, "workState")}</td>
        <td className="tl ph2 pv3 f7">
          {stateOptions(states, "residenceState")}
        </td>
      </tr>
    );
  };

  const renderNewApprovalRow = () => {
    const NEW = "new";
    return (
      <>
        <tr className="bb" key={NEW}>
          <td className="tl ph2 pv3 f7">
            <Field
              name="selectedPolicy"
              type="radio"
              value={NEW}
              checked={selectedValue === NEW}
              onChange={handleRadioChange}
            />
          </td>
          <td colSpan={4}>
            <div className="dt">
              <span className="dtc v-mid lh-copy f5 fw4">+</span>
              <span className="dtc tl ph2 pv3 f7 fw4 lh-copy">
                Create a new job category approval <br />
                <span className="gray">
                  Note: Select if you need to change job category, work and/or
                  home state.
                </span>
              </span>
            </div>
          </td>
        </tr>
        {selectedValue === NEW && renderNewApprovalFields()}
      </>
    );
  };

  const renderPreapprovalRows = (preApprovals) => {
    const rows = preApprovals.map((obj) => {
      return (
        <tr className="bb" key={obj?.quoteId}>
          <td className="tl ph2 pv3 f7">
            <Field
              name="selectedPolicy"
              type="radio"
              value={obj?.quoteId}
              checked={selectedValue === obj?.quoteId}
              onChange={handleRadioChange}
            />
          </td>
          <td className="tl ph2 pv3 f7">
            <Field
              type="text"
              className="dn"
              name="jobCategoryCode"
              value={obj?.jobCategoryCode}
            />
            {startAndEnd(obj?.jobCategory)}
          </td>
          <td className="tl ph2 pv3 f7">{obj?.coverageTypes}</td>
          <td className="tl ph2 pv3 f7">
            {obj?.quoteJsonWorkState ? obj?.quoteJsonWorkState : "-"}
          </td>
          <td className="tl ph2 pv3 f7">
            {obj?.quoteJsonResidenceState ? obj?.quoteJsonResidenceState : "-"}
          </td>
        </tr>
      );
    });
    return rows;
  };

  const renderPreapprovalTable = (preApprovals) => {
    return (
      <div className="w-100">
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
          <thead>
            <tr className="bb b--light-gray">
              <th className="tl pv3 ph2 fw5 f7 ttu w-5"></th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-40">Job Category</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-20">Coverage type</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">Work state</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">Residence state</th>
            </tr>
          </thead>
          <tbody>
            {renderPreapprovalRows(preApprovals)}
            {renderNewApprovalRow()}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage ? renderError() : null}
      <div
        className="overflow-y-scroll bt bb b--black-20 pb4"
        style={{ height: "520px" }}
      >
        <div className="relative dib w-100 pv3 f6">
          <div className="mv3">
            <h3 className="dark-gray bb b--black-10 mt0 pb3">
              Select an Approved Job Category
            </h3>
            {renderPreapprovalTable(preapprovals)}
          </div>
          <div className="mt4">
            <h3 className="dark-gray bb b--black-10 mt0 pb3">Client Entity</h3>
            <div className="pb2">
              <span className="w-100">
                <label htmlFor="entityName" className="f6 fw5 db mb2 mt3">
                  Client entity name
                </label>
                <Field
                  name="entityName"
                  style={getStyles(errors, "entityName", touched)}
                  className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                  type="text"
                  value={values.entityName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="e.g., Salesforce"
                />
              </span>
            </div>
            <div className="mv3">
              <label className="f6 pointer">
                <input
                  type="checkbox"
                  onClick={() => setClientEntityAddress(!clientEntityAddress)}
                />{" "}
                Add client entity address
              </label>
            </div>
            {clientEntityAddress && (
              <div className="w-100">
                <div className="pb2">
                  <span className="w-100">
                    <label
                      htmlFor="entityAddressLine1"
                      className="absolute o-0 f6 fw5 db mb2 mt3"
                    >
                      Address line 1
                    </label>
                    <Field
                      name="entityAddressLine1"
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                      value={values.entityAddressLine1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address line 1 (e.g., 123 Main St)"
                    />
                  </span>
                </div>

                <div className="pb2">
                  <span className="w-100">
                    <label
                      htmlFor="entityAddressLine2"
                      className="absolute o-0 f6 fw5 db mb2 mt3"
                    >
                      Address line 2
                    </label>
                    <Field
                      name="entityAddressLine2"
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                      value={values.entityAddressLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address line 2"
                    />
                  </span>
                </div>

                <div className="pb2">
                  <span className="w-100">
                    <label
                      htmlFor="entityAddressLine3"
                      className="absolute o-0 f6 fw5 db mb2 mt3"
                    >
                      Address line 3
                    </label>
                    <Field
                      name="entityAddressLine3"
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                      value={values.entityAddressLine3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address line 3"
                    />
                  </span>
                </div>

                <div className="db">
                  <div className="pb2 dib w-50 mr2">
                    <span className="w-100">
                      <label
                        htmlFor="entityAddressLocality"
                        className="absolute o-0 f6 fw5 db mb2 mt3"
                      >
                        City
                      </label>
                      <Field
                        name="entityAddressLocality"
                        className="outline-0 pa2 f6 fw3 ba b--black-10 br2 w-100"
                        type="text"
                        value={values.entityAddressLocality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="City"
                      />
                    </span>
                  </div>

                  <div className="pb2 dib w-20 ml2">
                    <span className="w-100">
                      <label
                        htmlFor="entityAddressRegion"
                        className="absolute o-0 f6 fw5 db mb2 mt3"
                      >
                        State
                      </label>
                      <Field
                        name="entityAddressRegion"
                        className="outline-0 pa2 f6 fw3 ba b--black-10 br2 w-100"
                        type="text"
                        value={values.entityAddressRegion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="State"
                      />
                    </span>
                  </div>

                  <div className="pb2 dib w-25 ml2">
                    <span>
                      <label
                        htmlFor="entityAddressPostalcode"
                        className="absolute o-0 f6 fw5 db mb2 mt3"
                      >
                        Zip Code
                      </label>
                      <Field
                        name="entityAddressPostalcode"
                        className="outline-0 pa2 f6 fw3 ba b--black-10 br2 w-100"
                        type="text"
                        value={values.entityAddressPostalcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Zip code"
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt4">
            <h3 className="dark-gray bb b--black-10 mt0 pb3">Job Details</h3>

            <div className="pb2">
              <span className="w-100">
                <label htmlFor="jobName" className="f6 fw5 db mb2 mt3">
                  Job name
                </label>
                <Field
                  name="jobName"
                  style={getStyles(errors, "jobName", touched)}
                  className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                  type="text"
                  placeholder="e.g., Instagram Influencer Post"
                  value={values.jobName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </span>
            </div>

            <div className="pb2">
              <span className="w-100">
                <label htmlFor="jobDescription" className="f6 fw5 db mb2 mt3">
                  Description
                </label>
                <Field
                  name="jobDescription"
                  style={getStyles(errors, "jobDescription", touched)}
                  className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                  component="textarea"
                  rows="4"
                  cols="40"
                  placeholder="A 2-3 sentence description of the work that the contractor will be doing."
                  value={values.jobDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </span>
            </div>

            <div className="db">
              <div className="pb2 dib w-40 mr2">
                <label htmlFor="effectiveDate" className="f6 fw5 db mb2 mt3">
                  Effective Date
                </label>
                <DayPickerInput
                  inputProps={{ name: "effectiveDate" }}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={FORMAT}
                  dayPickerProps={{ disabledDays: { before: today } }}
                  placeholder={`${dateFnsFormat(today, FORMAT)}`}
                  onDayChange={(date) => setFieldValue("effectiveDate", date)}
                />
              </div>
              <div className="pb2 dib w-40 mr2">
                <label htmlFor="endDate" className="f6 fw5 db mb2 mt3">
                  End Date
                </label>
                <DayPickerInput
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={FORMAT}
                  dayPickerProps={{
                    disabledDays: {
                      before: values.effectiveDate,
                      after: oneYear,
                    },
                  }}
                  placeholder={`${dateFnsFormat(tomorrow, FORMAT)}`}
                  onDayChange={(date) => setFieldValue("endDate", date)}
                />
              </div>
            </div>

            <div className="pb2">
              <span className="w-100">
                <label htmlFor="jobWage" className="f6 fw5 db mb2 mt3">
                  Wage
                </label>
                <div className="w-100 relative mv3 dt dib">
                  <div className="b--black-10 bb bt bl pa2 br2 br--left dtc dib black-50 w1">
                    $
                  </div>
                  <Field
                    name="jobWage"
                    style={getStyles(errors, "jobWage", touched)}
                    className="dtc dib bt bb outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 br--right"
                    type="text"
                    placeholder="e.g., 5000"
                    value={values.jobWage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </span>
            </div>
          </div>
          {isPurchaseOrderWorkflowEnabled && (
            <div className="mt4">
              <h3 className="dark-gray bb b--black-10 mt0 pb3">
                Purchase Order Contact Info
              </h3>
              <div className="pb2">
                <span className="w-100">
                  <label
                    htmlFor="purchaseOrderContactInfo.name"
                    className="f6 fw5 db mb2 mt3"
                  >
                    Name
                  </label>
                  <Field
                    name="purchaseOrderContactInfo.name"
                    style={getStyles(
                      errors,
                      "purchaseOrderContactInfo.name",
                      touched
                    )}
                    className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                    type="text"
                    placeholder="e.g., John Doe"
                  />
                </span>
              </div>
              <div className="pb2">
                <span className="w-100">
                  <label
                    htmlFor="purchaseOrderContactInfo.email"
                    className="f6 fw5 db mb2 mt3"
                  >
                    Email
                  </label>
                  <Field
                    name="purchaseOrderContactInfo.email"
                    style={getStyles(
                      errors,
                      "purchaseOrderContactInfo.email",
                      touched
                    )}
                    className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                    type="text"
                    placeholder="e.g., john.doe@example.com"
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fr mt3">
        <button
          type="submit"
          disabled={isSubmitting}
          className="f6 link dim br2 ph3 pv2 dib white bg-blue hover-bg-blue-80 bn pointer"
        >
          Create
        </button>
      </div>
    </Form>
  );
};

function JobAssignmentModal(props) {
  const { user, data, isPurchaseOrderWorkflowEnabled } = props;

  const { loading: jobCategoryLoading, data: categoryCodeData } = useQuery(
    JOB_CATEGORY_QUERY,
    {
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    createQuote,
    {
      loading: createQuoteLoading,
      error: createQuoteError,
      reset: createQuoteReset,
    },
  ] = useMutation(CREATE_QUOTE, {
    onCompleted: (data) => {
      if (!data.createQuote?.ok) {
        props.setErrorMessage(true);
        createQuoteReset();
      } else {
        props.closeModal();
        props.setSuccessMessage(data.createQuote?.message);
        props.setErrorMessage(false);
        createQuoteReset();
      }
    },
    refetchQueries: ["Contractor", "ContractorByID"],
  });

  const [
    createAssignment,
    { loading: createAssignmentLoading, error, reset: createAssignmentReset },
  ] = useMutation(CREATE_ASSSIGNMENT, {
    onCompleted: (data) => {
      if (!data.createAssignment?.ok) {
        props.setErrorMessage(true);
        createAssignmentReset();
      } else {
        props.closeModal();
        props.setNewAssignments({ node: data.createAssignment?.assignment });
        props.setSuccessMessage(data.createAssignment?.message);
        props.setErrorMessage(false);
        createAssignmentReset();
      }
    },
    onError: (error) => {
      props.setErrorMessage(true);
      createAssignmentReset();
    },
    refetchQueries: ["Contractor", "ContractorByID"],
  });

  const getQuoteWithActivePolicy = (quotes) => {
    return quotes.find((obj) => obj?.node?.policy?.edges[0]?.node?.isActive);
  };

  const initialQuote = getQuoteWithActivePolicy(data?.quotes?.edges);

  const quoteId = initialQuote?.node?.publicId;
  const contractor = data?.publicId;
  const jobCategoryCode = initialQuote?.node?.job?.jobCategory?.code;
  const residenceState = initialQuote?.node?.residenceState;
  const workState = initialQuote?.node?.workState;

  const formInitialValues = {};
  formInitialValues.selectedPolicy = quoteId;
  formInitialValues.contractor = contractor;
  formInitialValues.effectiveDate = today;
  formInitialValues.endDate = tomorrow;
  formInitialValues.coverageTypes = "workers-comp";
  formInitialValues.workState = workState || "AL";
  formInitialValues.residenceState = residenceState || "AL";

  formInitialValues.jobName = "";
  formInitialValues.jobDescription = "";
  formInitialValues.jobCategoryCode = jobCategoryCode;
  formInitialValues.jobWage = "";

  formInitialValues.entityName = "";
  formInitialValues.entityRequiredCoverage = ["workers-comp"];
  formInitialValues.entityAddressLine1 = "";
  formInitialValues.entityAddressLine2 = "";
  formInitialValues.entityAddressLine3 = "";
  formInitialValues.entityAddressLocality = "";
  formInitialValues.entityAddressRegion = "";
  formInitialValues.entityAddressPostalcode = "";
  formInitialValues.entityAdditionalInsuredMsg = "";

  formInitialValues.purchaseOrderContactInfo = {
    name: user?.name || "",
    email: user?.email || "",
  };

  function initValues(values) {
    const job = {
      name: values.jobName,
      description: values.jobDescription,
      categoryCode: values.jobCategoryCode,
      wage: parseInt(values.jobWage),
    };

    const requiredCoverageWc = {
      coverageType: values.entityRequiredCoverage[0],
    };

    const address = {
      line1: values.entityAddressLine1,
      line2: values.entityAddressLine2,
      line3: values.entityAddressLine3,
      locality: values.entityAddressLocality,
      region: values.entityAddressRegion,
      postalcode: values.entityAddressPostalcode,
    };

    const entity = {
      name: values.entityName,
      address: address,
      requiredCoverage: [requiredCoverageWc],
    };

    const purchaseOrderContactInfo = {
      name: values.purchaseOrderContactInfo.name,
      email: values.purchaseOrderContactInfo.email,
    };

    const result = {
      job: job,
      entity: entity,
    };

    if (isPurchaseOrderWorkflowEnabled) {
      result.purchaseOrderContactInfo = purchaseOrderContactInfo;
    }

    return result;
  }

  function createNewQuote(values) {
    const { job, entity, purchaseOrderContactInfo } = initValues(values);

    const variables = {
      contractor: values.contractor,
      workState: values.workState,
      residenceState: values.residenceState,
      job: job,
      coverageType: values.coverageTypes,
      entity: entity,
      effectiveDate: values.effectiveDate,
      endDate: values.endDate,
    };

    if (isPurchaseOrderWorkflowEnabled) {
      variables.purchaseOrderContactInfo = purchaseOrderContactInfo;
    }

    createQuote({ variables });
  }

  function createJobAssignment(values) {
    const { job, entity, purchaseOrderContactInfo } = initValues(values);

    const variables = {
      contractor: values.contractor,
      effectiveDate: values.effectiveDate,
      quote: values.selectedPolicy,
      endDate: values.endDate,
      job: job,
      entity: entity,
    };

    if (isPurchaseOrderWorkflowEnabled) {
      variables.purchaseOrderContactInfo = purchaseOrderContactInfo;
    }

    createAssignment({ variables });
  }

  function createJobAssignmentOrQuote(values) {
    if (values.selectedPolicy === "new") {
      createNewQuote(values);
    } else {
      createJobAssignment(values);
    }
  }

  const renderLoading = () => (
    <ReactLoading type={"spin"} color={"#cccccc"} className="center" />
  );

  const renderModalBody = () => (
    <div className="roboto">
      <PageHeader title={"Create Job Assignment"} />
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting, setTouched }) => {
          setTouched({
            entityName: true,
            jobName: true,
            jobDescription: true,
            jobWage: true,
          }); // Mark all fields as touched on submit
          setSubmitting(true);
          createJobAssignmentOrQuote(values);
        }}
        validationSchema={getValidationSchema(isPurchaseOrderWorkflowEnabled)}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(formikProps) => (
          <JobAssignmentForm
            {...props}
            {...formikProps}
            categoryCodeData={categoryCodeData}
          />
        )}
      </Formik>
    </div>
  );

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel={props.contentLabel}
    >
      {jobCategoryLoading || createAssignmentLoading || createQuoteLoading
        ? renderLoading()
        : renderModalBody()}
    </Modal>
  );
}

function EditWageModal(props) {
  const {
    data,
    wageEditedAssignment,
    setWageEditedAssignment,
    wageEditModalIsOpen,
    setWageEditModalIsOpen,
  } = props;

  const [adminOverride, setAdminOverride] = useState(false);

  function renderWageEditModal() {
    const [wageValue, setWageValue] = useState("");

    const loadingSpinner = () => (
      <ReactLoading type={"spin"} color={"#cccccc"} width={24} height={24} />
    );

    const UpdateWageButton = ({ submitting, disabled }) => {
      if (submitting) {
        return (
          <button className="button-reset bg-animate white mt0 bn ttu pa3 w-100 mb2 hover-white br2">
            <ReactLoading
              type={"spin"}
              color={"#cccccc"}
              height={20}
              width={20}
              className="center"
            />
          </button>
        );
      } else {
        return (
          <button
            type="submit"
            disabled={disabled}
            className={classNames(
              "f6 link br2 ph3 pv2 dib white bn pointer",
              {
                "bg-blue hover-bg-blue-80": !disabled,
                "bg-light-gray o-100": disabled
              }
            )}
          >
            Update
          </button>
        );
      }
    };

    const handleInputChange = (e) => {
      setWageValue(e.target.value);
    };

    const closeWageModal = () => {
      setWageEditModalIsOpen(false);
      setWageEditedAssignment(null);
    };

    const {
      data: invoiceHistoryData,
      loading: invoiceHistoryLoading,
      error: invoiceHistoryError,
    } = useQuery(ASSIGNMENT_INVOICE_HISTORY, {
      variables: { jobId: wageEditedAssignment?.job?.publicId },
      skip: !wageEditModalIsOpen,
      onCompleted: (data) => {
        const invoiceHistoryData = data.assignmentInvoiceHistory;
        const latestInvoiceGrossPay =
          invoiceHistoryData[invoiceHistoryData.length - 1]?.grossPay;
        if (latestInvoiceGrossPay) {
          setWageValue(centsToDollars(latestInvoiceGrossPay, true));
        }
      },
    });

    const [updateAssignmentWage, { loading, error, reset }] = useMutation(
      UPDATE_ASSIGNMENT_WAGE,
      {
        onCompleted: () => {
          setTimeout(() => {
            closeWageModal();
          }, 750);

          reset();
        },
        refetchQueries: ["AssignmentInvoiceHistory"],
      }
    );

    const handleSubmit = () => {
      updateAssignmentWage({
        variables: {
          jobId: wageEditedAssignment?.job.publicId,
          contractorId: data?.publicId,
          newWage: parseInt(wageValue),
          adminOverride: adminOverride
        }
      });
    };

    const customModalStyles = {
      ...modalStyles,
      content: {
        ...modalStyles.content,
        width: "1000px",
      },
    };

    return (
      <Modal
        isOpen={wageEditModalIsOpen}
        onRequestClose={closeWageModal}
        style={customModalStyles}
        contentLabel="Edit Wage"
      >
        <Formik initialValues={{ newWage: "" }} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="roboto relative invoice-history-modal">
                <h3 className="dark-gray bb b--black-10 mt0 pb3">
                  Edit Job Wage
                </h3>

                <div>
                  {invoiceHistoryLoading ? (
                    loadingSpinner()
                  ) : invoiceHistoryError ? (
                    <p>Error loading invoice history</p>
                  ) : invoiceHistoryData &&
                    invoiceHistoryData.assignmentInvoiceHistory.length > 0 ? (
                    <div className="mv4">
                      <h4 className="f5 fw6">Invoice History</h4>

                      <div>
                        <table
                          className="f6 w-100 mw8 center ba br3 b--solid b--black-10"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th className="fw6 tl pa3">Invoice</th>
                              <th className="fw6 tl pa3">Gross Pay</th>
                              <th className="fw6 tl pa3">Premium Due</th>
                              <th className="fw6 tl pa3">Created</th>
                            </tr>
                          </thead>
                          <tbody className="lh-copy">
                            {invoiceHistoryData.assignmentInvoiceHistory.map(
                              (record) => (
                                <tr
                                  key={record.publicId}
                                  className={
                                    record.voided
                                      ? "voided relative"
                                      : "relative"
                                  }
                                >
                                  <td className="pa3 relative br bt b--black-10">
                                    <div>
                                      {record.voided && (
                                        <div>
                                          <div className="stripes"></div>
                                          <span className="red ttu f6 br2 db fw6 lh-title h1 voided-badge">
                                            voided
                                          </span>
                                        </div>
                                      )}
                                      {!record.voided && record.paidInFull && (
                                        <span className="green ttu f6 br2 db fw6 lh-title h1 paid-in-full-badge">
                                          paid
                                        </span>
                                      )}
                                      {record.publicId}
                                    </div>
                                  </td>
                                  <td className="pa3 br bt b--black-10">
                                    {centsToDollars(record.grossPay)}
                                  </td>
                                  <td className="pa3 br bt b--black-10">
                                    {centsToDollars(record.premiumDue)}
                                  </td>
                                  <td className="pa3 br bt b--black-10">
                                    <span className="db">
                                      {moment
                                        .utc(record.created)
                                        .local()
                                        .fromNow()}
                                    </span>
                                    <small className="db f7 black-40">
                                      {record.created}
                                    </small>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <p>No invoice history available.</p>
                  )}
                </div>

                {!invoiceHistoryData?.assignmentInvoiceHistory ? (
                  <></>
                ) : (props.isAdmin || !invoiceHistoryData?.assignmentInvoiceHistory?.[0]?.agencyPayInvoiceId) && (
                  <div className="pb2">
                    <h4 className="f5 fw6">Change gross pay</h4>

                    <div className="w-100">
                      <label htmlFor="newWage" className="f6 fw5 db mb2 mt3">
                        <span className="db pb1">
                          {wageEditedAssignment?.job?.name}
                        </span>
                        <small className="db gray">
                          ({wageEditedAssignment?.job.publicId})
                        </small>
                      </label>

                      {props.isAdmin && invoiceHistoryData?.assignmentInvoiceHistory?.[0]?.agencyPayInvoiceId && (
                        <div className="mb3">
                          <label className="f6 flex items-center">
                            <input
                              type="checkbox"
                              className="mr2"
                              checked={adminOverride}
                              onChange={(e) => setAdminOverride(e.target.checked)}
                            />
                            <span className="red">
                              I understand this will override an outstanding invoice
                            </span>
                          </label>
                        </div>
                      )}

                      <div className="w-20 relative mv3 dt dib wage-change-input">
                        <div className="b--black-10 bb bt bl pa1 pl2 pr2 br2 br--left dtc dib black-50 w1 sign">
                          $
                        </div>
                        <Field
                          name="newWage"
                          className="dtc dib bt bb outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 br--right"
                          type="text"
                          placeholder="e.g., 5000"
                          value={wageValue}
                          onChange={handleInputChange}
                          disabled={props.isAdmin && invoiceHistoryData?.assignmentInvoiceHistory?.[0]?.agencyPayInvoiceId && !adminOverride}
                        />

                        <div className="update-wage-button">
                          <UpdateWageButton 
                            submitting={isSubmitting} 
                            disabled={props.isAdmin && invoiceHistoryData?.assignmentInvoiceHistory?.[0]?.agencyPayInvoiceId && !adminOverride}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }

  return renderWageEditModal();
}

function AssignmentCancelHoverButton(props) {
  const [hover, setHover] = useState(false);

  const {
    coverageDates,
    isCancelled,
    jobPublicId,
    setCancelAssignmentModalValues,
    isAdmin,
    isIneligible,
    isAssignment,
  } = props;

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const adminOnlyCancelBtn = () => {
    return (
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setCancelAssignmentModalValues({
            cancelAssignmentModalIsOpen: true,
            assignment: jobPublicId,
            confirmed: false,
          });
        }}
        className={classNames(
          "outline-0 pointer br2 ba b--black-20 gray bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100 flex items-center justify-center"
        )}
      >
        <div className="flex items-center justify-center">
          <img className="dib v-mid w1" src="/cancel.svg" alt="Cancel" />
          <span className="f7 ml2">Cancel Coverage</span>
        </div>
      </button>
    );
  };

  const renderCoverageDates = () => (
    <button className="outline-0 br2 ba b--black-20 gray bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
      {coverageDates}
    </button>
  );

  const renderCancelledText = () => (
    <button className="outline-0 br2 ba b--black-20 gray bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
      Cancelled
    </button>
  )

  if (isCancelled) {
    return renderCancelledText();
  }

  return (
    <div
      className="dib w-100"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3"
    >
      {hover && isAdmin && !isCancelled && !isIneligible && isAssignment
        ? adminOnlyCancelBtn()
        : renderCoverageDates()}

    </div>
  );
}

function WorkHistoryTable(props) {
  const {
    data,
    jobsArray,
    policies,
    isAdmin,
    errors,
    touched,
    isActiveEdit,
    setFieldValue,
    setSuccessMessage,
    setNewAssignments,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  // Parse the current URL parameters
  const searchParams = new URLSearchParams(location.search);
  const urlTab = searchParams.get("workHistoryFilter") || "all";

  const [activeTab, setActiveTab] = useState(urlTab);

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const currentTab = currentParams.get("workHistoryFilter");

    if (currentTab !== activeTab) {
      const newParams = new URLSearchParams(location.search);
      newParams.set("workHistoryFilter", activeTab);
      navigate(`${location.pathname}?${newParams.toString()}`, {
        replace: true,
      });
    }
  }, [activeTab, location.search, navigate]);

  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const [assignmentChangeModalValues, setAssignmentChangeModalValues] = useState({
    assignmentChangeModalIsOpen: false,
    jobId: null,
    assignmentId: null,
    quoteId: null,
    node: null
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [wageEditModalIsOpen, setWageEditModalIsOpen] = useState(false);
  const [wageEditedAssignment, setWageEditedAssignment] = useState(null);
  const [modalErrorMessage, setModalErrorMessage] = useState(false);
  const [cancelAssignmentModalValues, setCancelAssignmentModalValues] =
    useState({
      cancelAssignmentModalIsOpen: false,
      assignment: "",
    });
  let [poContactEmailCopied, setPoContactEmailCopied] = useState();

  const onClose = () => {
    setPoContactEmailCopied(false);
  };

  const openEditAssignmentModal = (jobPublicId, assignmentId, quoteId, node) => {
    setAssignmentChangeModalValues({
      assignmentChangeModalIsOpen: true,
      jobId: jobPublicId,
      assignmentId,
      quoteId,
      node,
    });
  };

  const menuContent = (
    poContactName,
    poContactEmail,
    purchaseOrderId,
    jobCategory,
    policyId,
    quoteId,
    assignmentId,
    jobId,
    isAdmin,
    node
  ) => (
    <ul className="f7 list pv2 pl0 measure center">
      {policyId && <li className="lh-copy pv1 fw5 link">{policyId}</li>}
      {jobCategory && (
        <li className="lh-copy pv1 fw5 link">{startAndEnd(jobCategory)}</li>
      )}
      {poContactName && (
        <li className="lh-copy pv1 fw5 link dim pointer">{poContactName}</li>
      )}
      {poContactEmail && (
        <CopyToClipboard
          text={poContactEmail}
          onCopy={() => {
            setPoContactEmailCopied(true);
          }}
        >
          <li data-open={true} className="lh-copy pv1 fw5 link dim pointer">
            {poContactEmail}
            {poContactEmailCopied ? " - copied!" : " - copy"}
          </li>
        </CopyToClipboard>
      )}
      {purchaseOrderId && (
        <li className="lh-copy pv1 fw5 link">PO#{purchaseOrderId}</li>
      )}
      {isAdmin && (
        <li className="lh-copy pv1 fw5 link">
          <button
            className="outline-0 underline bg-white bw0 pa0 ma0 db pointer"
            onClick={() => openEditAssignmentModal(jobId, assignmentId, quoteId, node)}
            type="button"
          >
            Update details (admin only)
          </button>
        </li>
      )}
    </ul>
  );

  const filterJobs = (jobs, filter) => {
    const today = new Date();
    return jobs.filter((obj) => {
      const isAssignment = obj?.node?.__typename === "Assignment";
      const hasInsuranceApplications =
        obj?.node?.insuranceApplication?.url != null;
      const startDate = new Date(obj?.node?.effectiveDate);
      const endDate = new Date(obj?.node?.endDate);

      // First, apply the assignment/insurance application filter
      if (!(isAssignment || hasInsuranceApplications)) {
        return false;
      }

      // Then, apply the date-based filter
      switch (filter) {
        case "past":
          return endDate < today;
        case "current":
          return startDate <= today && today <= endDate;
        case "upcoming":
          return startDate > today;
        default:
          return true;
      }
    });
  };

  const filteredJobs = useMemo(
    () => filterJobs(jobsArray, activeTab),
    [jobsArray, activeTab]
  );

  const renderTabs = () => (
    <div className="flex mb3 bb b--light-gray">
      {["all", "current", "upcoming", "past"].map((tab) => (
        <button
          key={tab}
          className={classNames("f6 br1 ph3 pv3 bg-white pointer ttc", {
            "b--blue blue fw5 bb bt-0 bl-0 br-0": activeTab === tab,
            "b--light-gray black-50 bn": activeTab !== tab,
          })}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );

  const handleCoiClick = useCallback(async (event, url) => {
    event.stopPropagation();
    
    try {
      const token = props.token;
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Ten99Policy-Environment': localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
          'Ten99Policy-Organization': localStorage.getItem("Ten99PolicyOrganization") || "",
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch signed URL');
      }

      const pdfData = await response.json();
      const signedUrl = pdfData?.wc_coi_pdf_url || pdfData?.gl_coi_pdf_url || pdfData?.media_coi_pdf_url;

      window.open(signedUrl, '_blank');

    } catch (error) {
      console.error('Error fetching COI:', error);
      // Optionally show error to user
    }
  }, [props.token]);

  const handleCopcClick = useCallback(async (event, url) => {
    event.stopPropagation();
    
    try {
      const token = props.token;
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Ten99Policy-Environment': localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
          'Ten99Policy-Organization': localStorage.getItem("Ten99PolicyOrganization") || "",
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch signed URL');
      }

      const data = await response.json();
      const signedUrl = data.signed_url;  // COPC response has direct signed_url

      window.open(signedUrl, '_blank');

    } catch (error) {
      console.error('Error fetching COPC:', error);
      // Optionally show error to user
    }
  }, [props.token]);

  function downloadCertificateLink(coverageType, pdfUrl) {
    if (!pdfUrl) return null;
    
    return (
      <span className="dib">
        <button
          onClick={(e) => coverageType === "COPC" ? handleCopcClick(e, pdfUrl) : handleCoiClick(e, pdfUrl)}
          className="dark-gray pointer bn bg-transparent pa0 underline-hover"
          style={{ marginRight: '8px' }}
        >
          {coverageType}
        </button>
      </span>
    );
  }

  const formatCoverageType = (coverageTypes) => {
    if (!coverageTypes) return "";

    let names = Array.isArray(coverageTypes)
      ? coverageTypes
      : coverageTypes?.edges?.map((edge) => edge.node.name) || [];

    return names
      .map((coverage) => {
        if (coverage === WORKERS_COMP) {
          return "WC";
        } else if (coverage === GENERAL) {
          return "GL";
        } else if (coverage === MEDIA) {
          return "MEDIA";
        }
        return coverage;
      })
      .join(", ");
  };

  const renderWorkHistoryRows = (jobs) => {
    const usStates = states;

    // Sort jobs by creation date (newest first)
    const sortedJobs = [...jobs].sort((a, b) => {
      const dateA = new Date(
        a?.node?.createdAt || a?.node?.job?.createdAt || 0
      );
      const dateB = new Date(
        b?.node?.createdAt || b?.node?.job?.createdAt || 0
      );
      return dateB - dateA;
    });

    function coveragePeriod(effectiveDate, endDate) {
      const formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        const day = d.getDate().toString().padStart(2, "0");
        const year = d.getFullYear().toString().slice(-2);
        return `${month}/${day}/${year}`;
      };

      const start = formatDate(effectiveDate);
      const end = formatDate(endDate);
      return `${start} – ${end}`;
    }

    const rows = sortedJobs.map((obj, index) => {
      const isAssignment = obj?.node?.__typename == "Assignment";
      const hasPolicy = obj?.node?.policy?.edges?.length > 0 || Boolean(obj?.node?.policy?.publicId);
      const cancelled = isAssignment ? obj?.node?.cancelled : null;

      const residenceState = isAssignment
        ? obj?.node?.residenceState
        : obj?.node?.quoteJsonResidenceState || obj?.node?.residenceState;
      const workState = isAssignment
        ? obj?.node?.workState
        : obj?.node?.quoteJsonWorkState;

      // Get coverage type
      let coverageType = "";
      if (isAssignment) {
        if (obj?.node?.coverageType && obj?.node?.coverageType.length > 0) {
          coverageType = formatCoverageType(obj?.node?.coverageType);
        } else if (hasPolicy) {
          coverageType = formatCoverageType(
            obj?.node?.policy?.quote?.coverageType
          );
        } else {
          coverageType = "N/A";
        }
      } else {
        coverageType = formatCoverageType(obj?.node?.coverageType);
      }

      const states = [workState, residenceState];
      const filteredStates = states
        .filter((state) => state !== null)
        .join(" / ");

      const certNode = isAssignment
        ? obj?.node?.certificate?.edges[0]?.node
        : obj?.node?.policy?.edges[0]?.node;
      const hasUpcomingPayment = isAssignment
        ? obj?.node?.hasUpcomingPayment
        : null;

      const wcPdfUrl = certNode?.protectedWcUrl ? certNode?.protectedWcUrl : null;
      const glPdfUrl = certNode?.protectedGlUrl ? certNode?.protectedGlUrl : null;
      const mediaPdfUrl = certNode?.protectedMediaUrl ? certNode?.protectedMediaUrl : null;
      
      const pdfUrl = !wcPdfUrl && !glPdfUrl && !mediaPdfUrl ? certNode?.pdfUrl : null;
      const copcUrl = certNode?.copcUrl ? certNode?.copcUrl : null;

      const assignmentEligibilityStatus = obj?.node?.eligibilityStatus
        ? JSON.parse(obj?.node?.eligibilityStatus)
        : null;
      const isIneligible =
        assignmentEligibilityStatus &&
        assignmentEligibilityStatus?.result === false;

      const FORMAT = "MM/dd/yyyy";
      const today = new Date();

      const poContactName = obj?.node?.job?.poContactName;
      const poContactEmail = obj?.node?.job?.poContactEmail;
      const purchaseOrderId = obj?.node?.invoice?.purchaseOrderId;

      const showPopoverMenu =
        poContactName ||
        poContactEmail ||
        purchaseOrderId ||
        obj?.node?.job?.jobCategory?.className ||
        obj?.node?.policy?.edges?.[0]?.node?.publicId;

      const assignmentId = isAssignment ? obj?.node?.publicId : null;
      const quoteId = !isAssignment ? obj?.node?.publicId : null;

      const jobId = obj?.node?.job?.publicId;
      const policyId = isAssignment
        ? obj?.node?.policy?.publicId
        : obj?.node?.policy?.edges?.[0]?.node?.publicId;

      const renderStatus = () => {
        const isCancelled = isAssignment
          ? obj?.node?.cancelled
          : obj?.node?.policy?.edges?.[0]?.node &&
            !obj?.node?.policy?.edges?.[0]?.node?.isActive;

        const hasInvoice = isAssignment && obj?.node?.invoice;
        const isExpired = !isAssignment && !hasPolicy && new Date() > new Date(obj?.node?.endDate);

        if (isCancelled) {
          return (
            <span className="f7 ba br-pill mv1 ph3 dib red bg-washed-red b--red">
              Cancelled
            </span>
          );
        } else if (isAssignment && hasUpcomingPayment) {
          return (
            <span className="f7 ba br-pill mv1 ph3 dib blue bg-washed-blue b--blue">
              Pmt due{" "}
              {moment
                .utc(obj?.node?.creditCardChargeDatetime)
                .from(moment.utc())}
            </span>
          );
        } else if (isIneligible) {
          const menuContent = () => (
            <div className="pv2 ph3">
              <p className="ma0 f7 lh-copy mw5">
                {assignmentEligibilityStatus?.message}
              </p>
            </div>
          );

          return (
            <PopoverMenu 
              positions={['top']}
              menuContent={menuContent}
            >
              <span className="f7 ba br-pill mv1 ph2 dib red bg-washed-red b--red pointer inline-flex items-center">
                Not covered 
                <img 
                  src="/info.svg" 
                  alt="info" 
                  className="w1 ml2 v-mid" 
                  style={{ width: '12px', height: '12px' }} 
                />
              </span>
            </PopoverMenu>
          );
        } else if (isAssignment && !hasInvoice) {
          return (
            <span className="f7 ba br-pill mv1 ph3 dib blue bg-washed-blue b--blue">
              Eligible
            </span>
          );
        } else if (isExpired) {
          return (
            <span className="f7 ba br-pill mv1 ph3 dib red bg-washed-red b--red">
              Expired
            </span>
          );
        } else if (!isAssignment && !hasPolicy) {
          return (
            <span className="f7 ba br-pill mv1 ph3 dib gray bg-washed-gray b--gray">
              Pending
            </span>
          );
        } else {
          return (
            <span className="f7 ba br-pill mv1 ph3 dib green bg-washed-green b--green">
              Covered
            </span>
          );
        }
      };

      return (
        <tr className="bb assignment-table-row" key={obj?.node?.job?.publicId}>
          <td className="tl ph2 pv3 f7">
            {isActiveEdit ? (
              <Field
                name={`jobs[${index}].node.job.entity.name`}
                style={getStyles(
                  errors,
                  `jobs[${index}].node.job.entity.name`,
                  touched
                )}
                font="12px Roboto"
                fontWeight="300"
                component={AutoSizingInputAdvanced}
                className="outline-0 dib f7 fw3 ba b--black-10 br2 pv2"
                type="text"
                autoComplete="off"
              />
            ) : (
              <span className="dib f7 fw3 ba b--transparent gray">
                {startAndEnd(obj?.node?.job?.entity?.name, 35)}
              </span>
            )}
          </td>
          <td className="tl ph2 pv3 f7">
            {obj?.node?.job?.name} ({obj?.node?.job?.publicId})
          </td>
          <td className="tl ph2 pv3 f7">{renderStatus()}</td>
          <td className="tl ph2 pv3 f7">
            {coverageType} &nbsp; (
            {isActiveEdit ? (
              <>
                <Field
                  name={`jobs[${index}].node.workState`}
                  component="select"
                  className="outline-0 dib f7 fw3 ba b--black-10 br2 pv1"
                >
                  {usStates.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))}
                </Field>
                <span> / {residenceState}</span>
              </>
            ) : (
              filteredStates
            )}
            )
          </td>
          <td className="tl ph2 pv3 f7">
            {isActiveEdit ? (
              <>
                <Field
                  name={`jobs[${index}].node.effectiveDate`}
                  className="outline-0 dib f7 fw3 ba b--black-10 br2 pv1"
                  readOnly={!isActiveEdit}
                >
                  {({ field, form }) => (
                    <DayPickerInput
                      field={field}
                      form={form}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format={FORMAT}
                      value={dateFnsFormat(new Date(field.value), FORMAT)}
                      onDayChange={(date) => {
                        setFieldValue(
                          `jobs[${index}].node.effectiveDate`,
                          date
                        );
                      }}
                      className="ba b--black-10 f7"
                      placeholder={`${dateFnsFormat(today, FORMAT)}`}
                    />
                  )}
                </Field>
                <Field
                  name={`jobs[${index}].node.endDate`}
                  className="outline-0 dib f7 fw3 ba b--black-10 br2 pv1"
                  readOnly={!isActiveEdit}
                >
                  {({ field, form }) => (
                    <DayPickerInput
                      field={field}
                      form={form}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format={FORMAT}
                      value={dateFnsFormat(new Date(field.value), FORMAT)}
                      onDayChange={(date) => {
                        setFieldValue(`jobs[${index}].node.endDate`, date);
                      }}
                      className="ba b--black-10 f7"
                      placeholder={`${dateFnsFormat(today, FORMAT)}`}
                    />
                  )}
                </Field>
              </>
            ) : (
              <AssignmentCancelHoverButton
                jobPublicId={obj?.node?.job?.publicId}
                coverageDates={coveragePeriod(
                  obj?.node?.effectiveDate,
                  obj?.node?.endDate
                )}
                isCancelled={cancelled}
                setCancelAssignmentModalValues={setCancelAssignmentModalValues}
                isAdmin={isAdmin}
                isIneligible={isIneligible}
                isAssignment={isAssignment}
                setAssignmentChangeModalValues={setAssignmentChangeModalValues}
              />
            )}
          </td>
          <td className="tl ph2 pv3 f7 relative">
            {isActiveEdit ? (
              <Field
                name={`jobs[${index}].node.job.wage`}
                style={getStyles(
                  errors,
                  `jobs[${index}].node.job.wage`,
                  touched
                )}
                component={CurrencyInput}
                className="outline-0 dib f7 fw3 ba b--black-10 br2 pv2 w-100"
              />
            ) : obj?.node?.invoice?.grossPay && !cancelled ? (
              <div className="relative wage-viewport">
                <div className="wage-container absolute pt1">
                  {centsToDollars(obj?.node?.invoice?.grossPay)}
                </div>
                <div className="edit-button-container absolute">
                  <button
                    className="db v-mid outline-0 pointer br2 ba b--black-20 bg-white pa1 ml0 f7 lh-title bg-animate hover-bg-light-gray border-box"
                    onClick={() => {
                      setWageEditModalIsOpen(true);
                      setWageEditedAssignment(obj?.node);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ) : (
              centsToDollars(obj?.node?.job?.wage)
            )}
          </td>
          <td className="tc ph2 pv3 f7">
            {obj?.node?.totalPremiumOwed
              ? centsToDollars(obj?.node?.totalPremiumOwed)
              : "---"}
          </td>
          <td className="tl ph2 pv3 f7" style={{ whiteSpace: 'nowrap' }}>
            {wcPdfUrl && downloadCertificateLink("WC", wcPdfUrl)}
            {glPdfUrl && downloadCertificateLink("GL", glPdfUrl)}
            {mediaPdfUrl && downloadCertificateLink("MEDIA", mediaPdfUrl)}

            {!wcPdfUrl && !glPdfUrl && !mediaPdfUrl && pdfUrl && downloadCertificateLink("COI", pdfUrl)}
          </td>
          {isAdmin && (
            <td className="tl ph2 pv3 f7" style={{ whiteSpace: 'nowrap' }}>
              {copcUrl && downloadCertificateLink("COPC", copcUrl)}
            </td>
          )}
          <td className="pv3 ph3 f7 tc">
            {showPopoverMenu && (
              <PopoverMenu
                onClose={onClose}
                menuContent={() =>
                  menuContent(
                    poContactName,
                    poContactEmail,
                    purchaseOrderId,
                    obj?.node?.job?.jobCategory?.className,
                    policyId,
                    quoteId,
                    assignmentId,
                    jobId,
                    isAdmin,
                    obj?.node
                  )
                }
              >
                <img
                  className="w1 pointer"
                  src="/ellipsis.svg"
                  alt="job history menu"
                />
              </PopoverMenu>
            )}
          </td>
        </tr>
      );
    });

    return (
      <>
        <UpdateAssignmentModal
          modalIsOpen={assignmentChangeModalValues.assignmentChangeModalIsOpen}
          closeModal={() => {
            setAssignmentChangeModalValues({
              assignmentChangeModalIsOpen: false,
              jobId: null,
              assignmentId: null,
              quoteId: null,
            })
            setModalErrorMessage(false)
          }}
          assignmentChangeModalValues={assignmentChangeModalValues}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setModalErrorMessage}
          data={data}
        />
        {rows}
      </>
    );
  };

  const renderEmptyRow = (columnCount) => (
    <tr>
      <td colSpan={columnCount} className="f6 tc pv3">
        No results found.
      </td>
    </tr>
  );

  const renderWorkHistoryTable = (jobs) => {
    const columnCount = isAdmin ? 10 : 9;

    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mb3">
          <thead>
            <tr className="bb b--light-gray">
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">Client</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">Assignment</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-20">Status</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">
                Coverage (Work / Home)
              </th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-20">Coverage Period</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">Wage</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">Premium</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-5">COI</th>
              {isAdmin && (
                <th className="tl pv3 ph2 fw5 f7 ttu">
                  Placement
                  <br />
                  Confirmation
                </th>
              )}
              <th className="tl pv3 ph2 fw5 f7 ttu w-10"></th>
            </tr>
          </thead>
          <tbody>
            {jobs.length > 0
              ? renderWorkHistoryRows(jobs)
              : renderEmptyRow(columnCount)}
          </tbody>
        </table>
      </div>
    );
  };

  function renderWorkHistoryContent(jobsArray) {
    // Sort filtered jobs
    const sortedJobs = [...filteredJobs].sort((a, b) => {
      const dateA = new Date(
        a?.node?.createdAt || a?.node?.job?.createdAt || 0
      );
      const dateB = new Date(
        b?.node?.createdAt || b?.node?.job?.createdAt || 0
      );
      return dateB - dateA;
    });

    return (
      <>
        {renderTabs()}
        {renderWorkHistoryTable(sortedJobs)}
        <EditWageModal
          wageEditModalIsOpen={wageEditModalIsOpen}
          setWageEditModalIsOpen={setWageEditModalIsOpen}
          wageEditedAssignment={wageEditedAssignment}
          setWageEditedAssignment={setWageEditedAssignment}
          {...props}
        />
      </>
    );
  }

  return (
    <>
      <div className="mt5">
        <div className="db dt bb b--black-10 w-100">
          <div className="dtc w-70">
            <h3 className="dark-gray db v-btm">Work History</h3>
          </div>
          {policies.length > 0 && (
            <div className="dtc relative">
              <button
                type="button"
                onClick={() => setModalIsOpen(true)}
                className="db v-mid outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box absolute bottom-1 right-0"
              >
                + Add Job Assignment
              </button>
            </div>
          )}
        </div>

        <div className="gray f6 fw2 lh-copy">
          {jobsArray.length > 0 ? (
            renderWorkHistoryContent(jobsArray)
          ) : (
            <span className="db mt3">No jobs reported.</span>
          )}
        </div>
      </div>
      <CancelAssignmentModal
        openModal={() =>
          setCancelAssignmentModalValues({
            ...cancelAssignmentModalValues,
            cancelAssignmentModalIsOpen: true,
          })
        }
        closeModal={() => {
          setCancelAssignmentModalValues({
            ...cancelAssignmentModalValues,
            cancelAssignmentModalIsOpen: false,
          });
          setModalErrorMessage(false);
        }}
        modalIsOpen={cancelAssignmentModalValues.cancelAssignmentModalIsOpen}
        assignment={cancelAssignmentModalValues.assignment}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setModalErrorMessage}
        modalErrorMessage={modalErrorMessage}
        token={props.token}
      />
      <JobAssignmentModal
        {...props}
        setNewAssignments={setNewAssignments}
        setSuccessMessage={setSuccessMessage}
        errorMessage={modalErrorMessage}
        setErrorMessage={setModalErrorMessage}
        data={data}
        openModal={() => setModalIsOpen(true)}
        closeModal={() => {
          setModalIsOpen(false);
          setModalErrorMessage(false);
        }}
        modalIsOpen={modalIsOpen}
        contentLabel={"Create Job Assignment"}
      />
    </>
  );
}

export default WorkHistoryTable;
